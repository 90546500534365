import logo from "../assets/logo-white.webp";

// Packages
import { IoChevronDown } from "react-icons/io5";
import { Link as AnimateLink } from "react-scroll";

const HeroSection = () => {
  return (
    <div className="container hero-section">
      <img className="hero-logo" src={logo} alt="logo" />
      <AnimateLink className="hero-scroll" to="donateContainer" smooth={true}>
        <button className="btn btn-transparent text-blue">
          <IoChevronDown size={50} />
        </button>
      </AnimateLink>
    </div>
  );
};

export default HeroSection;
