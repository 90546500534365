import placeholder from "../assets/portrait-cropped.png";

// Components
import ContactPreview from "../components/previews/ContactPreview";
import DonateContainer from "../components/DonateContainer";
import HeroSection from "../components/HeroSection";
import JoinForm from "../components/JoinForm";
import MeetShawnPreview from "../components/previews/MeetShawnPreview";
import UpdatesForm from "../components/UpdatesForm";
import WhatIBelievePreview from "../components/previews/WhatIBelievePreview";

// Packages
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div className="wrapper">
      <HeroSection />
      <img src={placeholder} alt="" className="hero-img" />
      <DonateContainer />
      <MeetShawnPreview />
      {/* <WhatIBelievePreview />
      <ContactPreview />
      <Link className="btn btn-red btn-block donate-btn" to="/donate">
        Donate
      </Link>
      <UpdatesForm />
      <JoinForm /> */}
    </div>
  );
};

export default Home;
