import logo from "../assets/logo-white.png";

// Packages
import { Link } from "react-router-dom";
import { Link as AnimateLink } from "react-scroll";
import {
  IoLogoFacebook,
  IoLogoInstagram,
  IoLogoTwitter,
} from "react-icons/io5";

const Footer = () => {
  return (
    <div className="footer text-white">
      <div className="footer-logo-container bg-white">
        <img
          src={logo}
          alt="Shawn Ellen LaGrua | Supreme Court of Georgia"
          className="footer-logo"
        />
      </div>
      {/* <ul className="footer-links">
        <li className="footer-item">
          <Link className="footer-link" to="/">
            Home
          </Link>
        </li>
        <li className="footer-item">
          <Link className="footer-link" to="/meet-shawn">
            Meet Shawn
          </Link>
        </li>
        <li className="footer-item">
          <Link className="footer-link" to="/contact">
            Contact
          </Link>
        </li>
        <li className="footer-item">
          <Link className="footer-link" to="/donate">
            Donate
          </Link>
        </li>
        <li className="footer-social-row">
          <a className="nav-link" href="https://facebook.com/">
            <IoLogoFacebook size={35} />
          </a>
          <a className="nav-link" href="https://twitter.com/">
            <IoLogoTwitter size={35} />
          </a>
          <a className="nav-link" href="https://instagram.com/">
            <IoLogoInstagram size={35} />
          </a>
        </li>
      </ul> */}
      <div className="container">
        <div className="footer-links-wrapper">
          <ul className="footer-links footer-links-column-1">
            <li className="footer-item">
              <a
                className="footer-link"
                href="https://secure.anedot.com/keep-justice-shawn-ellen-lagrua-inc/donate"
                target="_"
              >
                Donate
              </a>
            </li>
            <li className="footer-item">
              <AnimateLink to="meetLaGrua" smooth={true}>
                <button className="footer-link btn btn-transparent">
                  Meet Justice LaGrua
                </button>
              </AnimateLink>
            </li>
          </ul>
          <div className="preview-spacer"></div>
          <ul className="footer-links footer-links-column-2">
            <h1 className="container-body text-white">Contact Us</h1>
            <li className="footer-item text-white">
              <a href="mailto:kristin@theoblandergroup.com">
                kristin@theoblandergroup.com
              </a>
            </li>
            <li className="footer-item"></li>
          </ul>
        </div>
        <div className="preview-spacer footer-spacer"></div>
        <div className="committee-footer text-white">
          Paid for by Keep Justice Shawn Ellen LaGrua, Inc.
        </div>
        <div className="preview-spacer footer-spacer"></div>
        <p>1071 West Peachtree Street, #77754, Atlanta, GA 30357</p>
      </div>
    </div>
  );
};

export default Footer;
