import placeholderCropped from "../../assets/portrait-cropped.png";

// Packages
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const MeetShawnPreview = () => {
  const isPC = useMediaQuery({
    query: "(min-device-width: 500px)",
  });

  return (
    <div className="meet-shawn" id="meetLaGrua">
      <img src="" alt="" className="hero-img" />
      <div className={`container bg-white ${!isPC && "center"}`}>
        <h1 className="container-header text-blue">Meet Justice LaGrua</h1>
        <div className="container-body">
          <p>
            Governor Brian Kemp appointed Justice Shawn Ellen LaGrua to the
            Georgia Supreme Court in December 2020, and she was sworn in by
            Governor Kemp on January 7, 2021.
          </p>
          <p>
            Prior to joining the Supreme Court, Justice LaGrua was appointed to
            the Superior Court of the Atlanta Judicial Circuit by then-Governor
            Sonny Perdue and was sworn in on June 29, 2010. She served on the
            bench in the Superior Court of the Atlanta Judicial Circuit until
            January 2021, having been elected in 2012 and reelected in 2016 and
            2020. Prior to her appointment to the bench, she served as Inspector
            General for the Georgia Secretary of State. From 2004 until 2007,
            she served as the Solicitor General for DeKalb County. Judge LaGrua
            has over 20 years of trial experience, including her work as Chief
            Senior Assistant District Attorney in DeKalb and Fulton counties and
            as Chief Assistant District Attorney in the Tallapoosa Circuit.
          </p>
          <p>
            While serving on the bench in Fulton County, Justice LaGrua
            initiated and presided over “My Journey Matters,” a pilot probation
            program designed to divert young offenders from a lifetime of
            incarceration, and presided over the Court’s first Re-Entry Court,
            which worked to integrate prior offenders back into the community
            using close supervision, substance abuse counseling, job-training,
            and advancing education.
          </p>
          <p>
            Justice LaGrua currently serves as the Chair of Supreme Court Chief
            Justice Harold D. Melton’s COVID-19 Task Force and Chair of the
            State Bar&#39;s Bench and Bar Committee. In 2019, she was appointed
            by Governor Kemp to serve as a member of the Judicial Nominating
            Commission. Justice LaGrua was appointed to the Georgia Child
            Support Commission and served as Chair of the Judicial Council’s
            Process Servers Committee. Justice LaGrua also served as the
            President of the Council of Superior Court Judges in 2019-2020.
            While on the Superior Court she served on the Legislative Support
            Team, as vice-chair for the Access to Fairness and Justice
            Committee, and as a member of the Accountability Court and Bench and
            Bar Committees for the Council of Superior Court Judges. She is a
            Member of the Criminal Justice Reform Sub-Committee. In 2016, Judge
            LaGrua received the St. Thomas Moore Award from the St. Thomas Moore
            Society in recognition of “specific actions manifesting a commitment
            to justice and humanity, especially in difficult circumstances”
            related in part to her formation of and continued commitment to the
            My Journey Matters Program. Judge LaGrua taught Advanced Evidence
            and Litigation for her alma mater, Georgia State University College
            of Law and has been a speaker and presenter at multiple conferences
            and seminars during her career, presenting on topics such as RICO,
            State Bar Professionalism during COVID-19, and others.
          </p>
          <p>
            Justice LaGrua is a Master of the Logan E. Bleckley Inn of Court and
            Lamar Inn of Court. She is a founding member and past Chair of the
            Georgia Association of Women Lawyers, Judicial Application Review
            Committee. She served as the Chair of the Judicial Section of the
            State Bar of Georgia. She is an alumna member of Leadership Atlanta,
            Class of 2012, and served as the Vice Chair of the Criminal Justice
            Day for 2014-2015 and 2019.
          </p>
          <p>
            Justice LaGrua graduated from the University of Georgia and received
            her Juris Doctorate from the Georgia State University College of
            Law.
          </p>
          <p>
            Justice LaGrua grew up in a military family where her father served
            in the JAG Corps and her mother served in the Red Cross. She is
            married to husband Chris and has a fur-child, Grizzley.
          </p>
        </div>
        {/* <p className="container-body">
          Governor Brian Kemp appointed Justice Shawn Ellen LaGrua to the
          Georgia Supreme Court in December 2020, and she was sworn in by
          Governor Kemp on January 7, 2021. Prior to then, Justice LaGrua was
          appointed to the Superior Court of the Atlanta Judicial Circuit and
          presided over community initiatives, such as the "My Journey Matters"
          and the first Re-Entry Court programs.
        </p> */}
        {/* <div className="preview-spacer">
          <Link className="btn btn-red" to="/meet-shawn">
            Learn More
          </Link>
        </div> */}
      </div>
    </div>
  );
};

export default MeetShawnPreview;
