import { useEffect, useState } from "react";

// Components
import { useParams } from "react-router-dom";
import CheckoutForm from "../components/CheckoutForm";
import DonationAmounts from "../components/DonationAmounts";

// Packages
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe("pk_test_2pFFX0391PADiQ1otmi3ueZi00yJv0zSDM");

const CARD_ELEMENT_OPTIONS = {
  fonts: [
    {
      cssSrc:
        "url(https://fonts.googleapis.com/css?family=Poppins:wght@200;400;700;900)",
      family: "Poppins",
      style: "normal",
    },
  ],
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Poppins", sans-serif',
      fontSmoothing: "antialiased",
      "::placeholder": {
        color: "#004aad",
      },
    },
    invalid: {
      color: "#d50031",
      iconColor: "#d50031",
    },
  },
};

const Donate = () => {
  const [amount, setAmount] = useState("");
  const [showAmount, setShowAmount] = useState(false);
  const [recurring, setRecurring] = useState(false);

  const { propsAmount } = useParams();

  useEffect(() => {
    setAmount(parseInt(propsAmount));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const donationAmounts = DonationAmounts;

  return (
    <div>
      <div className="container header-container bg-red">
        <h1 className="container-header">Contribute</h1>
      </div>
      <div className="container donation-container text-blue">
        <div className="donation-sub-container">
          <h1 className="container-sub-heading">Select an Amount</h1>
          <div className="container-body">
            <div className="donation-group">
              {donationAmounts.map((donationAmount) => (
                <span key={donationAmount}>
                  <button
                    className={`donation-group-item btn btn-${
                      amount === donationAmount ||
                      propsAmount === donationAmount
                        ? "red"
                        : "blue"
                    } btn-block`}
                    onClick={() => {
                      setAmount(donationAmount);
                      setShowAmount(false);
                    }}
                  >
                    ${donationAmount}
                    {recurring && "/mo"}
                  </button>
                </span>
              ))}
              <button className="donation-group-item btn btn-blue">
                ${" "}
                <input
                  className="donation-input"
                  type="number"
                  step="0.01"
                  onChange={(e) => {
                    setShowAmount(true);
                    setAmount(e.target.value);
                  }}
                  onBlur={(e) =>
                    setAmount(
                      e.target.value !== ""
                        ? parseFloat(e.target.value).toFixed(2)
                        : ""
                    )
                  }
                  value={showAmount ? amount : ""}
                />
                {recurring && "/mo"}
              </button>
            </div>
          </div>
          {/* <button className="btn btn-transparent">
            <input
              type="checkbox"
              id="recurring"
              // value={recurring}
              onChange={(e) => setRecurring(e.target.checked)}
              checked={recurring}
            />
            <label htmlFor="recurring">Make it monthly!</label>
          </button> */}
        </div>
        <div className="donation-sub-container">
          <h1 className="container-sub-heading">Checkout</h1>
          <Elements
            stripe={stripePromise}
            options={CARD_ELEMENT_OPTIONS}
            // fonts={[
            //   {
            //     cssSrc:
            //       "https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;700;900&display=swap",
            //   },
            // ]}
          >
            <CheckoutForm
              amount={amount}
              // recurring={recurring}
            />
          </Elements>
        </div>
      </div>
    </div>
  );
};

export default Donate;
