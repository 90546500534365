const DonateContainer = () => {
  return (
    <div
      className="container donate-container text-white text-center bg-red"
      id="donateContainer"
    >
      <h1 className="container-header">Donate Today</h1>
      <a
        className="btn btn-blue btn-center"
        href="https://secure.anedot.com/keep-justice-shawn-ellen-lagrua-inc/donate"
        target="_"
      >
        Contribute
      </a>
    </div>
  );
};

export default DonateContainer;
