import "./App.css";

// Components
import Contact from "./views/Contact";
import Donate from "./views/Donate";
import DonateFooter from "./components/DonateFooter";
import Footer from "./components/Footer";
import Home from "./views/Home";
import MeetShawn from "./views/MeetShawn";
import Navbar from "./components/Navbar";
import ScrollToTop from "./components/ScrollToTop";
import WhatIBelieve from "./views/WhatIBelieve";

// Views

// Packages
import { Switch, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <ScrollToTop>
        {/* <Navbar />
        <div className="navbar-spacer"></div> */}
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          {/* <Route path="/meet-shawn" exact>
            <MeetShawn />
          </Route>
          <Route path="/what-i-believe" exact>
            <WhatIBelieve />
          </Route>
          <Route path="/contact" exact>
            <Contact />
          </Route>
          <Route path="/donate" exact>
            <Donate />
          </Route>
          <Route path="/donate/:propsAmount" exact>
            <Donate />
          </Route> */}
        </Switch>
        <Footer />
        {/* <DonateFooter /> */}
      </ScrollToTop>
    </div>
  );
}

export default App;
